<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
      
      <spinner v-if="loading" />

      <div v-if="error" class="alert alert-danger">{{error}}</div>

      <div v-if="message">
        
        <div v-if="action == 'block'">
          <p>Thank you. Your e-mail address <span class="fw-bold">{{email}}</span> has been successfully registered in the <span class="fw-bold">Block Me Registry</span> at Ma Labs.</p>
          <p>You will no longer receive e-mails from our sales personnel. Please note that it may take up to 24 hours for the "Block Me" registration to become effective.</p>
          <p>Note: This confirmation only applies to the registered e-mail address <span>{{email}}</span>. If you own other e-mail addresses or e-mail accounts, and wish to register them as part of the "Block Me" Registry, please do so individually for each e-mail address or account.</p>
        </div>

        <div v-if="action == 'unblock'">
          <p>Thank you. Your e-mail address <span class="fw-bold">{{email}}</span> has been successfully unblocked from our Sales Department.</p>
          <p>You may receive e-mail from our sales personnel regarding our product line or future promotions.</p>
          <p>Please note that it may take up to 24 hours for unblocking your e-mail to become effective.</p>
        </div>
        
      </div>
      

      
  </div>

  <div id="google_recaptcha"></div>
</template>

<script>
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import Spinner from "../../components/Spinner.vue";
import { ref } from 'vue'
import { useRoute } from "vue-router";
import { getAPI } from '../../utils/axios-api'

export default {
  name: 'BlockMeProcess',
  components: { BreadcrumbPage, Spinner },
  props: ['action'],
  setup(props) {
    const loading = ref(true)
    const error = ref(null)
    const message = ref(null)
    const action = ref(null)
    const email = ref(null)
    
    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Home", link: "Home"})
    breadcrumb_links.value.push({text: "Block Me Registration", link: ""})

    //get url parameters
    const route = useRoute();
    if (typeof route.query.confirm != "undefined" && route.query.confirm != "") {

      getAPI.get('/account/blockme/process/' + props.action + "/" + route.query.confirm + "/")
        .then((response) => {
          let res = response.data
          action.value = res["action"]
          email.value = res["email"]
          message.value = res["message"]
          
          loading.value = false
        })
        .catch(err => {
          error.value = err.response.data.message
          loading.value = false
        })
    }
    else {
      error.value = "Invalid URL. Failed to process the request."
      loading.value = false
    }
    

    return {
      loading,
      error,
      message,
      action,
      email,
      breadcrumb_links
    }

  }
}
</script>

<style>
.anchor {
  cursor: pointer;
  color:#000;
}
.anchor:hover {
  color:cornflowerblue;
}
#info {
  display:none;
}
</style>